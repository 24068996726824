import React, { useState } from "react";
import axiosServices from "../utils/axios";
import { useTranslation } from "react-i18next";
// All countries
// length 252
const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua & Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Caribbean Netherlands", code: "BQ" },
  { name: "Bosnia & Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo - Brazzaville", code: "CG" },
  { name: "Congo - Kinshasa", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Côte d’Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Islas Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard & McDonald Islands", code: "HM" },
  { name: "Vatican City", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "North Korea", code: "KP" },
  { name: "South Korea", code: "KR" },
  { name: "Kosovo", code: "XK" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Laos", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "North Macedonia", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia", code: "FM" },
  { name: "Moldova", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar (Burma)", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Curaçao", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn Islands", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Réunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russia", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "St. Barthélemy", code: "BL" },
  { name: "St. Helena", code: "SH" },
  { name: "St. Kitts & Nevis", code: "KN" },
  { name: "St. Lucia", code: "LC" },
  { name: "St. Martin", code: "MF" },
  { name: "St. Pierre & Miquelon", code: "PM" },
  { name: "St. Vincent & Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "São Tomé & Príncipe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Serbia", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Sint Maarten", code: "SX" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia & South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard & Jan Mayen", code: "SJ" },
  { name: "Eswatini", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syria", code: "SY" },
  { name: "Taiwan", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad & Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks & Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "U.S. Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Vietnam", code: "VN" },
  { name: "British Virgin Islands", code: "VG" },
  { name: "U.S. Virgin Islands", code: "VI" },
  { name: "Wallis & Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
function GeneratePassword({ states, setStates, user, setBasicDetails }) {
  const { t } = useTranslation();
  const [name, setName] = useState(`${user?.firstName} ${user?.lastName}`);
  const [email, setEmail] = useState(user?.emailAddress);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [gender, setGender] = useState("");
  const [DOB, setDOB] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };
  const validateInput = (e) => {
    let { name, value } = e.target;
    if (!user?.isPasswordGenerated) {
      setError((prev) => {
        const stateObj = { ...prev, [name]: "" };

        switch (name) {
          case "password":
            if (!value) {
              stateObj[name] = t("Please enter Password.");
            } else if (
              input.confirmPassword &&
              value !== input.confirmPassword
            ) {
              stateObj["confirmPassword"] = t(
                "Password and Confirm Password does not match."
              );
            } else {
              stateObj["confirmPassword"] = input.confirmPassword
                ? ""
                : error.confirmPassword;
            }
            break;

          case "confirmPassword":
            if (!value) {
              stateObj[name] = t("Please enter Confirm Password.");
            } else if (input.password && value !== input.password) {
              stateObj[name] = t(
                "Password and Confirm Password does not match."
              );
            }
            break;

          default:
            break;
        }

        return stateObj;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setBasicDetails({
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      name,
      email,
      country,
    });

    axiosServices
      .post("/api/client-personal-detail/customer", {
        gender,
        dob: DOB,
        userId: user?.id,
      })
      .then((response) => {
        if (response?.data?.status === "success" || "Success") {
          setIsLoading(false);
          if (!user?.isPasswordGenerated) {
            axiosServices
              .put("/api/customers/generate-password", {
                password: input?.password,
              })
              .then((res) => {
                if (res?.data?.status === "success" || "Success") {
                  setInput({
                    password: "",
                    confirmPassword: "",
                  });
                  setStates({ step: 2, proccedToPay: true });
                }
              })
              .catch((err) =>
                alert(t(err?.message) || err || "Something Went Wrong!")
              );
          } else if (user?.isPasswordGenerated) {
            setStates({ step: 2, proccedToPay: true });
          }
        }
      })
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {!states?.proccedToPay && (
        <>
          <div className="pb-2 text-xl font-bold">
            {t("Please Fill all the Details.")}
          </div>
          <form method="post" onSubmit={handleSubmit}>
            <div className="grid mb-2 md:grid-cols-1 items-center">
              <div>
                <div className="grid md:gap-2 gap-2 mb-2 sm:grid-cols-2  items-center">
                  <div>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                      type="text"
                      name="name"
                      required
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder={t("Enter Your Name")}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="email"
                      readonly
                      id="email"
                      value={email}
                      className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                    />
                  </div>
                </div>
                <div className="grid md:gap-2 gap-2 mb-2 sm:grid-cols-2 ">
                  <div>
                    <select
                      required
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      id="gender"
                      className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                    >
                      <option value="">{t("Choose your gender")}</option>
                      <option value="male">{t("Male")}</option>
                      <option value="female">{t("Female")}</option>
                    </select>
                  </div>

                  <div>
                    <input
                      placeholder="Select Your DOB"
                      type="date"
                      name="dob"
                      id="dob"
                      required
                      value={DOB}
                      onChange={(e) => setDOB(e.target.value)}
                      // placeholder="D.O.B"
                      className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                    />
                    {DOB === "" && (
                      <p
                        id="filled_error_help"
                        className="text-xs text-red-600 dark:text-red-400 ms-3"
                      >
                        {t("Select your Date of birth.")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-2">
                  <input
                    type="text"
                    multiple
                    name="addressLine1"
                    id="addressLine1"
                    required
                    value={addressLine1}
                    onChange={(e) => setAddressLine1(e.target.value)}
                    placeholder={t("Address Line 1")}
                    className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                  />
                </div>
                <div className="mb-2">
                  <input
                    type="text"
                    name="addressLine2"
                    id="addressLine2"
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                    placeholder={t("Address Line 2")}
                    className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                  />
                </div>
                <div className="grid lg:gap-2 md:gap-2 gap-2 mb-2 lg:grid-cols-3 md:grid-cols-2  sm:grid-cols-3 grid-cols-2 items-center">
                  <div>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      required
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder={t("City")}
                      className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      value={state}
                      required
                      onChange={(e) => setState(e.target.value)}
                      placeholder={t("State")}
                      className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      value={postalCode}
                      required
                      onChange={(e) => setPostalCode(e.target.value)}
                      placeholder={t("Postal Code")}
                      className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                    />
                  </div>
                  <div>
                    {/* <input
                      type="text"
                      name="country"
                      id="country"
                      value={country}
                      select
                      required
                      onChange={(e) => setCountry(e.target.value)}
                      placeholder={t("Country")}
                      className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                    /> */}
                    {/* <select
                      required
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      id="country"
                      className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                    >
                      <option value="">{t("Select your Country")}</option>
                      {countries &&
                        countries.map((country, key) => (
                          <option key={key} value={country?.code}>
                            {country?.name}
                          </option>
                        ))}
                    </select> */}
                  </div>
                </div>
                <div className="mb-2">
                  <select
                    required
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    id="country"
                    className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000]"
                  >
                    <option value="">{t("Select your Country")}</option>
                    {countries &&
                      countries.map((country, key) => (
                        <option key={key} value={country?.code}>
                          {country?.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {!user?.isPasswordGenerated && (
                <input
                  type="password"
                  name="password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000] mt-2"
                  placeholder={t("Enter Password")}
                  required
                  value={input.password}
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
              )}
              {error.password && <span className="err">{error.password}</span>}
              {!user?.isPasswordGenerated && (
                <input
                  type="password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 focus:outline-0 text-sm rounded-lg focus:ring-[#000000] focus:border-[#000000] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#000000] dark:focus:border-[#000000] mt-2"
                  name="confirmPassword"
                  placeholder={t("Enter Confirm Password")}
                  required
                  value={input.confirmPassword}
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
              )}
              {error.confirmPassword && (
                <span className="err">{error.confirmPassword}</span>
              )}

              {/* {!paymentButton && ( */}
              <button
                className="bg-[#000000] mb-2 mt-5"
                style={{
                  width: "100%",
                  padding: "8px",
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "400",
                  borderRadius: "10px",
                }}
                type="submit"
              >
                {isLoading ? t("Processing...") : t("Next")}
              </button>
            </div>
            {/* {paymentButton && <p>{t("Password Generated Succesfully!")}</p>} */}
          </form>
        </>
      )}
    </>
  );
}

export default GeneratePassword;
