import checkedIcon from "../icons8-check.svg";
import circleIcon from "../icons8-circle-30.png";
import React, { useEffect, useState } from "react";
import axiosServices from "../utils/axios";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const Package = ({
  user,
  setStripePriceID,
  allPackages,
  setAllPackages,
  currentPackagesDetails,
  setCurrentPackagesDetails,
  setFreeTrailDays,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const packageId = query.get("packageId");
  const [packageName, setPackageName] = useState("");

  useEffect(() => {
    axiosServices
      .get(`/api/packages/${user?.packageId ? user?.packageId : packageId}`)
      .then((res) => {
        if (res?.data?.status === ("Success" || "success")) {
          console.log(res, "responsePackageDetails");
          const responseData = res?.data?.data?.prices;
          setPackageName(res?.data?.data?.title);
          const priceIdData = responseData.filter(
            (price) => Number(price?.id) === Number(user?.priceId || 0)
          );
          setAllPackages(priceIdData[0]);
          setCurrentPackagesDetails(res?.data?.data);
          setStripePriceID(priceIdData[0]?.stripePriceID);
          setFreeTrailDays(priceIdData[0]?.freeTrialDays);
        }
      });
  }, [user]);
  return (
    <>
      <div className="packages p-3">
        <div
          className={`package-card border rounded-lg p-2 mb-3 bg-[#000000] flex justify-between text-white items-center shadow-xl opacity-100
          }`}
        >
          <div className="flex items-center gap-2">
            <div>
              <img src={checkedIcon} alt="checked" width={20} />
            </div>
            <div>
              <h4 className="text-lg font-medium">{packageName}</h4>
              <p>
                {t("Per")} {t(allPackages?.tenure)}
              </p>
            </div>
          </div>
          <div className="font-medium uppercase">
            {allPackages?.currency}{" "}
            {(
              Number(allPackages?.price) +
                Number(currentPackagesDetails?.securityDeposit) || 0
            ).toFixed(2)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Package;
