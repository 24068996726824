import React, { useEffect, useState } from "react";
import Package from "./Package";
import { Elements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import PaymentForm from "./PaymentForm";
import axiosServices from "../utils/axios";
import { useLocation } from "react-router-dom";
import Loading from "./Loading";
import SuccessImage from "../images/successful.png";
import ErrorImage from "../images/error.png";
import toast from "react-hot-toast";
import { STRIPE_SECRET } from "../config";

function PaymentPage({ user, varToken, stripePromise, basicDetails }) {
  const location = useLocation();
  const { t } = useTranslation();
  const query = new URLSearchParams(location.search);
  const sessionId = query.get("session_id");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [freeTrailDays, setFreeTrailDays] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const paymentStatus = query.get("payment_status");
  const [stripePriceID, setStripePriceID] = useState(undefined);
  const [allPackages, setAllPackages] = useState(undefined);
  const [currentPackagesDetails, setCurrentPackagesDetails] =
    useState(undefined);
  const [stripeCustomerId, setStripeCustomerId] = useState(
    user?.stripCustomerId
  );
  const stripe = require("stripe")(STRIPE_SECRET);
  console.log(allPackages, "allPackages");
  console.log(currentPackagesDetails, "currentPackagesDetails");
  console.log(freeTrailDays, "freeTrailDays");
  console.log(user, "user");
  console.log(basicDetails, "basicDetails");
  console.log(user?.subscriptionScheduleDate, "user?.subscriptionScheduleDate");

  const handleCheckout = async () => {
    // if (allPackages?.price === 0 || '0'){

    // }else{

    // }
    try {
      setIsLoading(true);
      let customerId = user?.stripCustomerId;

      // Create Stripe customer if not already existing
      if (!user?.stripCustomerId) {
        const customer = await stripe.customers.create({
          email: user.emailAddress,
          name: basicDetails?.name,
          address: {
            line1: basicDetails?.addressLine1,
            line2: basicDetails?.addressLine2,
            city: basicDetails?.city,
            state: basicDetails?.state,
            postal_code: basicDetails?.postalCode,
          },
          metadata: {
            ownerId: user?.id,
            ownerType: user?.userRole,
          },
        });
        customerId = customer?.id;
        setStripeCustomerId(customer?.id);

        // Update Stripe customer ID in user details
        await axiosServices.put(
          `/api/customers/update-stripe/${user.id}`,
          { stripCustomerId: customerId },
          { headers: { Authorization: `Bearer ${varToken}` } }
        );
      } else {
        const customer = await stripe.customers.update(user?.stripCustomerId, {
          email: user.emailAddress,
          name: basicDetails?.name,
          address: {
            line1: basicDetails?.addressLine1,
            line2: basicDetails?.addressLine2,
            city: basicDetails?.city,
            state: basicDetails?.state,
            postal_code: basicDetails?.postalCode,
          },
          metadata: {
            ownerId: user?.id,
            ownerType: user?.userRole,
          },
        });
        customerId = customer?.id;
        setStripeCustomerId(customer?.id);

        // Update Stripe customer ID in user details
        await axiosServices.put(
          `/api/customers/update-stripe/${user.id}`,
          { stripCustomerId: customerId },
          { headers: { Authorization: `Bearer ${varToken}` } }
        );
      }

      const priceCheck = await stripe.prices.retrieve(stripePriceID);

      // Create a subscription schedule
      // if (user?.subscriptionScheduleDate) {
      //   const date = new Date(user?.subscriptionScheduleDate);
      //   const unixTimestamp = Math.floor(date.getTime() / 1000);
      //   var subscriptionSchedule = await stripe.subscriptionSchedules.create({
      //     customer: customerId, // Replace with the customer ID
      //     start_date: unixTimestamp,
      //     end_behavior: "release",
      //     phases: [
      //       {
      //         items: [
      //           { price: stripePriceID, quantity: 1 },
      //           // {
      //           //   price: currentPackagesDetails?.stripeDepositeId,
      //           //   quantity: 1,
      //           // },
      //         ],
      //         iterations: 12, // 12 months, for example
      //       },
      //     ],
      //   });
      // }

      // Prepare the checkout session object
      let obj = {
        payment_method_types: ["card"],
        mode: priceCheck.recurring ? "subscription" : "payment",
        customer: customerId,
        metadata: {
          ownerId: user?.id,
          ownerType: user?.userRole,
          customerId: user?.id,
          packageId: currentPackagesDetails?.id,
          priceId: allPackages?.id,
        },
        billing_address_collection: "required",
        success_url: `${window.location.href}&session_id=${customerId}&payment_status=success`,
        cancel_url: `${window.location.href}&session_id=${customerId}&payment_status=failed`,
      };

      // Add tax rate amount

      if (priceCheck.recurring) {
        obj.subscription_data = {
          default_tax_rates: [
            currentPackagesDetails?.taxRateDetail?.stripTaxRateId,
          ],
        };

        // if (user?.subscriptionScheduleDate) {
        //   const futureDate = new Date(user?.subscriptionScheduleDate);
        //   const today = new Date();
        //   const timeDifference = futureDate - today;
        //   const daysDifference = Math.ceil(
        //     timeDifference / (1000 * 60 * 60 * 24)
        //   );
        //   obj.subscription_data = {
        //     trial_period_days: daysDifference,
        //     metadata: {
        //       subscription_schedule: subscriptionSchedule.id,
        //     },
        //   };
        // }

        if (currentPackagesDetails?.stripeDepositeId) {
          obj.line_items = [
            { price: stripePriceID, quantity: 1 },
            { price: currentPackagesDetails?.stripeDepositeId, quantity: 1 },
          ];
        } else {
          obj.line_items = [{ price: stripePriceID, quantity: 1 }];
        }
      } else {
        if (currentPackagesDetails?.stripeDepositeId) {
          obj.line_items = [
            {
              price: stripePriceID,
              quantity: 1,
              tax_rates: [
                currentPackagesDetails?.taxRateDetail?.stripTaxRateId,
              ],
            },
            {
              price: currentPackagesDetails?.stripeDepositeId,
              quantity: 1,
              tax_rates: [
                currentPackagesDetails?.taxRateDetail?.stripTaxRateId,
              ],
            },
          ];
        } else {
          obj.line_items = [
            {
              price: stripePriceID,
              quantity: 1,
              tax_rates: [
                currentPackagesDetails?.taxRateDetail?.stripTaxRateId,
              ],
            },
          ];
        }
        // obj.tax_behavior = {
        //   enabled: true,
        // };
        // obj.customer = customerId;
        // obj.price_data.tax_behavior = "inclusive";
        obj.invoice_creation = {
          enabled: true,
        };
      }

      // Add free trial period if applicable
      if (freeTrailDays && freeTrailDays !== (0 || "0")) {
        const currentDate = new Date();
        const trialEnd = new Date(
          currentDate.getTime() + freeTrailDays * 24 * 60 * 60 * 1000
        );
        const trialEndUnix = Math.floor(trialEnd.getTime() / 1000);
        console.log(trialEndUnix, "trialEndUnix");
        obj.subscription_data.trial_period_days = freeTrailDays;
      }

      // Create Stripe checkout session
      const session = await stripe.checkout.sessions.create(obj);
      console.log(session, "session");
      window.location.replace(session?.url);
    } catch (error) {
      console.log(error, "handle checkout error");
      toast.error(error?.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  // HANDLE PAYMENT RESPONSE WHEN STRIPE SEND ON SUCCESS URL OR CANCEL URL
  useEffect(() => {
    if (sessionId === stripeCustomerId && paymentStatus) {
      if (paymentStatus === "success") {
        setPaymentSuccess(true);
      } else {
        setPaymentError(true);
      }
    }
  }, [sessionId, paymentStatus, stripeCustomerId]);

  // useEffect(() => {
  //   const handlePaymentResponse = async () => {
  //     if (
  //       sessionId === stripeCustomerId &&
  //       paymentStatus === "success" &&
  //       user?.subscriptionScheduleDate
  //     ) {
  //       const stripe = require("stripe")(STRIPE_SECRET);
  //       const startDate = new Date(user?.subscriptionScheduleDate);
  //       const subscriptionSchedule = await stripe.subscriptionSchedules.create({
  //         customer: user?.stripCustomerId,
  //         start_date: Math.floor(startDate.getTime() / 1000),
  //         end_behavior: "release",
  //         phases: [
  //           {
  //             items: [{ price: stripePriceID, quantity: 1 }],
  //             iterations: 1,
  //           },
  //         ],
  //       });
  //       console.log(subscriptionSchedule, "subscriptionSchedule");
  //       // const startDate = new Date();
  //       console.log(Math.floor(startDate.getTime() / 1000), "startDate");
  //     }
  //   };

  //   if (paymentSuccess) {
  //     handlePaymentResponse();
  //   }
  // }, [
  //   paymentSuccess,
  //   sessionId,
  //   paymentStatus,
  //   stripeCustomerId,
  //   user?.subscriptionScheduleDate,
  //   stripePriceID,
  // ]);

  return (
    <>
      {paymentSuccess && (
        <div className="text-center m-auto py-3">
          <img src={SuccessImage} alt="Success Icon" className="m-auto" />
          <p className="my-2 text-green-600 font-bold w-10/12 m-auto">
            {t(
              "Thank You! Your payment has been success, now you can log in to the App"
            )}
          </p>
        </div>
      )}
      {paymentError && (
        <div className="text-center m-auto py-3">
          <img src={ErrorImage} alt="Success Icon" className="m-auto" />
          <p className="my-2 text-red-600 font-bold w-10/12 m-auto">
            {t("Ops! Something is wrong, Please try again")}
          </p>
          <button
            className="bg-[#000000] hover:bg-[#000000] text-white px-3 py-2 my-1 rounded-md"
            onClick={handleCheckout}
            disabled={isLoading}
          >
            {isLoading ? t("Loading...") : t("Try Again")}
          </button>
        </div>
      )}

      {!paymentSuccess && !paymentError && !isLoading && (
        <>
          <Package
            user={user}
            setStripePriceID={setStripePriceID}
            allPackages={allPackages}
            setAllPackages={setAllPackages}
            currentPackagesDetails={currentPackagesDetails}
            setCurrentPackagesDetails={setCurrentPackagesDetails}
            setFreeTrailDays={setFreeTrailDays}
          />
          {user?.schedulePlan === "yes" ? (
            <div className="px-4">
              <Elements stripe={stripePromise}>
                <PaymentForm
                  stripePriceID={stripePriceID}
                  user={user}
                  varToken={varToken}
                  currentPackagesDetails={currentPackagesDetails}
                  basicDetails={basicDetails}
                />
              </Elements>
            </div>
          ) : (
            <div className="text-center">
              <p className="mb-5">
                {t(
                  "Please click on the confirm checkout button, you will be redirect on stripe payment page."
                )}
              </p>
              <button
                className="bg-[#000000] hover:bg-[#000000] text-white px-3 py-2 rounded-md"
                onClick={handleCheckout}
                disabled={isLoading}
              >
                {isLoading ? t("Loading...") : t("Confirm Checkout")}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default PaymentPage;
